.hideOnXs {
  display: none;
  @media (min-width: 900px) {
    display: block;
  }
}
.imgAboutShift {
  @media (max-width: 600px) {
  margin-bottom: -35px!important;
  }
}
#root {
  min-width: 375px;
}
.App {
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.videoWrapper iframe {
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
}

.closeModalButton {
  display: none;
  padding: 10px;
  background: #444;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  color: #999;
}

.submitStoryLink {
  color: #fff;
}

.MuiTabs-root.AboutCaption-tabs {
  width: 100%;
}

.bottomContainerTextMailLink {
  color: #fff;
  opacity: 1;
  white-space: nowrap;
}

header {
  z-index: 999!important;
}
.menuDrop {
  position: absolute;
  right:0;
  top:100%;
  background-color: #0F2F4B;
  width:100%;
  z-index: 100;
  text-align: center;
  max-height:0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}
.menuDrop.menuDropOpen {
  max-height:100vh;
}

.text-normal {
  text-transform: none!important;
}

.dividerCol {
  position: relative;  
}

.dividerCol::after {
  content: "";
  position: absolute;
  top: 30px;
  bottom: 30px;
  width: 1px;
  background-color: #000;
  left: 40%;
  margin: auto;
}

@media (max-width: 900px) {
  .dividerCol {
    display: none;
  }
}
.quoteWrap {
  padding:20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dropAvatar {
  border-radius: 0 50% 50%!important;
}
.dropAvatar--gradient {
  padding: 15px;
  border-radius: 0px 30px 30px 30px;
  background: linear-gradient(180deg, rgba(0, 154, 222, 0.44) 0%, rgba(217, 217, 217, 0.00) 100%);
}
.dropQuote {
  font-family: Roboto Serif!important;
  font-size: 20px;
  font-style: italic;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0.14000000059604645px;
  text-align: left;
}
.dropFlex {
  padding: 15px;
  display: flex;
  column-gap: 20px;
}
.dropFlex--r {
  column-gap: 5px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
}
.quoteWrap--r {
  padding:0;
}
.dropFlex svg {
  flex-grow: 0;
  flex-shrink: 0;
  width: 20%;
}
.captionBy {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  opacity:0.65;
}
.captionBy__wrap {
  padding: 0 15px;
  min-height:150px;
}
.captionBy__wrap h4 {
  color: #0F2F4B;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.025px;
  margin-bottom:10px;
}
.gradient--up {
  background: var(--gradient, linear-gradient(180deg, rgba(0, 154, 222, 0.00) 0%, rgba(0, 154, 222, 0.22) 85%));
}
.gradient--down {
  position: relative;
  background: var(--gradient, linear-gradient(0deg, rgba(0, 154, 222, 0.00) 0%, rgba(0, 154, 222, 0.22) 85%));
}
.gradient--down--full:before {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background: var(--gradient, linear-gradient(0deg, rgba(0, 154, 222, 0.00) 0%, rgba(0, 154, 222, 0.22) 85%));
}
.gradient--down--full:after {
  content: "";
  position: absolute;
  top: 0;
  right: 100%;
  width: 100%;
  height: 100%;
  background: var(--gradient, linear-gradient(0deg, rgba(0, 154, 222, 0.00) 0%, rgba(0, 154, 222, 0.22) 85%));
}
.sidePic--right {
  position: absolute;
  left: calc(100% + 50px);
  top:-50px;
  bottom:-10px;
  width: 60%;
  background-color:red;
  background-position: center;
  background-size: cover;
  @media screen and (max-width: 1200px) {
    display: none;
  }
}

.selectFormControlLabel,
#demo-simple-select-label {
  margin-left: -14px!important;
  margin-top: 5px!important;
}
.selectFormControl,
#demo-simple-fc {
  margin-top: 5px!important;
  margin-left: 15px!important;
}
.heroClass {
  @media screen and (max-width: 760px) {
    background-size: 150%!important;
    background-repeat: no-repeat;
    background-position: top center!important;
    background-color: #011E3F;
  }
}
.tabStyles {
  @media screen and (max-width: 760px) {
    margin-left:0!important;
    padding-left:0!important;
    margin-right:0!important;
    padding-right:0!important;
  }
}
.storyHeader {
  @media screen and (min-width: 760px) {
    height: 65px;
  }
}
.resourceHeader {
  @media screen and (min-width: 760px) {
    min-height: 75px;
  }
}

.videoWraps {
  position: relative;
  padding-bottom: 56.5%; /* 16:9 */
  height: 0;
  overflow: hidden;
  margin-bottom: 20px;
}
.videoWraps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.menuButton {
  background-color: #fff!important;
}
.logosDesktop {
  display: none!important;
  @media screen and (min-width: 560px) {
    display: block!important;
  }
}
.logosMobile {
  display: block!important;
  @media screen and (min-width: 560px) {
    display: none!important;
  }
}
.videoHeadClass {
  @media screen and (min-width: 1200px) {
    padding-right:40px;
  }
}